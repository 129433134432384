import type { TableOfContents } from 'types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import parse, { domToReact, Element as ParsedElement } from 'html-react-parser';
import { Link, List, Paragraph } from '@leafwell/components';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useScrollNav } from 'hooks/useScrollNav';

export const ContentsTableList: React.FC<{
  className?: string;
  items: TableOfContents[];
}> = memo(({ className = '', items }) => {
  const router = useRouter();
  const { translations } = useWordPressContext();
  const [observable, setObservable] = useState(null);
  const [activeItem, setActiveItem] = useScrollNav(observable);

  useEffect(() => {
    function setInitialItems() {
      const newItems = items
        ?.map(({ id }) => {
          const anchor = document.getElementById(id);
          const prevSibling =
            anchor?.previousElementSibling ||
            (anchor?.parentNode as Element)?.previousElementSibling;

          if (prevSibling || anchor) {
            return {
              id,
              node: prevSibling || anchor,
            };
          }

          return null;
        })
        .filter(Boolean);

      if (newItems) {
        setObservable(newItems);
      }
    }

    router.events.on('routeChangeComplete', setInitialItems);

    return () => {
      router.events.off('routeChangeComplete', setInitialItems);
    };
  }, []);

  return (
    <section
      className={[
        'lg:scrollbar lg:scrollbar-thumb-green lg:max-h-1/2-screen-height lg:overflow-y-auto lg:overflow-x-hidden',
        className,
      ].join(' ')}
    >
      <Paragraph
        size="large"
        dangerouslySetInnerHTML={{
          __html: translations['Table of contents'],
        }}
      />
      <List type="number" className="pt-3 pl-6 lg:pr-2 text-sm">
        {items.map(({ id, label }) => (
          <Link
            {...{
              className: 'block py-1 lg:py-0',
              key: id,
              href: `#${id}`,
              onClick: () => setActiveItem({ id, scroll: true }),
              variant:
                activeItem === id ? 'underlineHighlight' : 'underlineHover',
            }}
          >
            {label}
          </Link>
        ))}
      </List>
    </section>
  );
});

const ContentsTable: React.FC<{
  className?: string;
  items?: TableOfContents[];
}> = ({ items, ...props }) => {
  return items?.length ? <ContentsTableList {...props} items={items} /> : null;
};

export default ContentsTable;
